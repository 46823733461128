interface State {
  trips: Trip[];
  isLoading: boolean;
}

const initialState: State = {
  trips: [],
  isLoading: false,
};

export enum TripActions {
  RequestTrips = "REQUEST_TRIPS",
  ReceiveTrips = "RECEIVE_TRIPS",
  AddTrip = "ADD_TRIP",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: TripActions; payload: any }
): State => {
  switch (type) {
    case TripActions.RequestTrips:
      return { ...state, isLoading: true };
    case TripActions.ReceiveTrips:
      return { ...state, trips: payload, isLoading: false };
    case TripActions.AddTrip: //agrego un producto
      const findTrip = state.trips.find((trip) => trip._id === payload._id);
      if (findTrip) {
        return {
          ...state,
          trips: state.trips.map((trip) =>
            trip._id === payload._id ? payload : trip
          ),
        };
      } else {
        return {
          ...state,
          trips: [...state.trips.filter((p) => p._id !== payload._id), payload],
        };
      }
    default:
      return state;
  }
};

export default reducer;
