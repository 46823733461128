import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import { addDriver, updateDriver } from "../../../services/driverService";
import { validateRUT } from "validar-rut";
import { useEffect } from "react";

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  driver: Driver | null;
  setDriver: (driver: Driver | null) => void;
}

const NewDriverModal: React.FC<Props> = ({
  open,
  onClose,
  driver,
  setDriver,
}) => {
  const initialValues = {
    name: "",
    rut: "",
  };

  const [values, setValues, handleChange] = useForm(initialValues);
  useEffect(() => {
    if (driver) {
      setValues({ ...values, name: driver.name, rut: driver.rut });
    }
  }, [driver]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onTransitionEnd={() => !open && setValues(initialValues)} //para borrar los values solo cuando se cierra
    >
      <DialogTitle>Nuevo Conductor</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2 mt-5">
          <TextField
            onChange={handleChange}
            disabled={!!driver}
            id="rut"
            label="Rut"
            value={values.rut}
          ></TextField>
          <TextField
            onChange={handleChange}
            id="name"
            label="Nombre"
            value={values.name}
          ></TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (driver !== null) {
              setDriver(null);
              setValues(initialValues);
            }
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={values.rut === "" || values.name === ""}
          onClick={async () => {
            var rutValido = validateRUT(values.rut);
            if (values.rut[values.rut.length - 1] === "0") {
              rutValido = true;
            }

            if (driver) {
              await updateDriver(driver._id, values);
              setDriver(null);
              onClose();
            } else if (rutValido) {
              await addDriver(values);
              setDriver(null);
              onClose();
            } else {
              alert("Rut invalido");
            }
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewDriverModal;
