import { useAppSelector } from "../../../hooks/storeHooks"


const Home: React.FC = () => {
    const user = useAppSelector((s) => s.authReducer.user)
    return (
        <div className="home">
          <img src={"bienvenido.png"} className="welcome-image mt-10" alt="Bienvenido" />
          <div className="welcome-title">¡Bienvenid@!</div>
          <div className="welcome-subtitle">
            {user?.name}
          </div>
        </div>
      )
    }
export default Home
