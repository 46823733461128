interface State {
    vehicles: Vehicle[]
    isLoading: boolean
}

const initialState: State = {
    vehicles: [],
    isLoading: false,
}

export enum VehicleActions {
    RequestVehicles = 'REQUEST_VEHICLES',
    ReceiveVehicles = 'RECEIVE_VEHICLES',
    AddVehicle = 'ADD_VEHICLE',
    DeleteVehicle = 'DELETE_VEHICLE',
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: VehicleActions; payload: any }
): State => {
    switch (type) {
        case VehicleActions.RequestVehicles: //hago el llamado para que la api me traiga los producots
            return { ...state, isLoading: true }
        case VehicleActions.ReceiveVehicles: //recibo el arreglo de productos
            return { ...state, vehicles: payload, isLoading: false }
        case VehicleActions.AddVehicle: //agrego un producto
            return {
                ...state,
                vehicles: [
                    ...state.vehicles.filter((p) => p._id !== payload._id),
                    payload,
                ],
            }
        case VehicleActions.DeleteVehicle:
            return {
                ...state,
                vehicles: state.vehicles.filter((p) => p._id !== payload._id),
            }
        default:
            return state
    }
}

export default reducer
