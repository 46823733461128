interface State {
    drivers: Driver[]
    isLoading: boolean
  }
  
  const initialState: State = {
    drivers: [],
    isLoading: false,
  }
  
  export enum DriverActions {
    RequestDrivers = 'REQUEST_DRIVERS',
    ReceiveDrivers = 'RECEIVE_DRIVERS',
    AddDriver = 'ADD_DRIVER',
    DeleteDriver = 'DELETE_DRIVER',
  }
  // el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
  const reducer = (
    state: State = initialState,
    { type, payload }: { type: DriverActions; payload: any }
  ): State => {
    switch (type) {
      case DriverActions.RequestDrivers: //hago el llamado para que la api me traiga los producots
        return { ...state, isLoading: true }
      case DriverActions.ReceiveDrivers: //recibo el arreglo de productos
        return { ...state, drivers: payload, isLoading: false }
      case DriverActions.AddDriver: //agrego un producto

        return {
          ...state,
          drivers: [
            ...state.drivers.filter((p) => p._id !== payload._id),
            payload,
          ],
        }
      case DriverActions.DeleteDriver:
        return {
          ...state,
          drivers: state.drivers.filter((p) => p._id !== payload._id),
        }
      default:
        return state
    }
  }
  
  export default reducer
  