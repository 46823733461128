interface State {
    customers: Customer[]
    isLoading: boolean
} 

const initialState: State = {
    customers: [],
    isLoading: false,
}

export enum CustomerActions {
    RequestCustomers = 'REQUEST_CUSTOMERS',
    ReceiveCustomers = 'RECEIVE_CUSTOMERS',
    AddCustomer = 'ADD_CUSTOMER',
    DeleteCustomer = 'DELETE_CUSTOMER',
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: CustomerActions; payload: any }
): State => {
    switch (type) {
        case CustomerActions.RequestCustomers: 
            return { ...state, isLoading: true }
        case CustomerActions.ReceiveCustomers: //recibo el arreglo de productos
            return { ...state, customers: payload, isLoading: false }
        case CustomerActions.AddCustomer: //agrego un producto
            return {
                ...state,
                customers: [
                    ...state.customers.filter((p) => p._id !== payload._id),
                    payload,
                ],
            }
        case CustomerActions.DeleteCustomer:
            return {
                ...state,
                customers: state.customers.filter((p) => p._id !== payload._id),
            }
        default:
            return state
    }
}

export default reducer